import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Col = styled.div`
  -ms-flex: 1 1 0px;
  flex: 1 1 0px;
  padding: 15px;
  min-width: 0;
  position: relative;
`

const FooterEnd = styled.div`
  background-color: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`
const FooterCont = styled.footer`
  line-height: 0.75rem;
  text-align: center;
  a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
`
const Footer = () => (
  <FooterCont>
    <div
      style={{
        backgroundColor: `#ffeb98`,
        padding: `1.5rem`,
        fontSize: `0.8rem`,
        color: `#000000`,
        lineHeight: `normal`,
      }}
    >
      <div>
        Libreria IlSegnalibro -
        <OutboundLink
          href="http://www.ilsegnalibromagenta.it"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: `#000000` }}
        >
          {" "}
          www.ilsegnalibromagenta.it
        </OutboundLink>
      </div>
      <div>via Roma 87/a 20013 Magenta (MI)</div>
      <div>Tel.0297297623 (adulti) - Tel.0297290713 (ragazzi e scolastica)</div>
      <div>e-mail: libreria@ilsegnalibromagenta.it</div>
    </div>
    <FooterEnd>
      <Col>
        <Link to="/contatti">Contatti</Link>
      </Col>
      <Col>© 2019-{new Date().getFullYear()}</Col>
      <Col>
        Designed with{" "}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span>{" "}
        by{" "}
        <OutboundLink href="https://www.giovannidemizio.eu">g-dem</OutboundLink>{" "}
        & Muffin
      </Col>
    </FooterEnd>
  </FooterCont>
)

export default Footer
