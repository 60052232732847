import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import navStyles from "../styles/nav.module.css"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const StyledLink = styled(props => <Link {...props}></Link>)`
  color: #ffffff;
  display: block;
  text-decoration: none;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`
const NavCont = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: #000000;
  color: #ffffff;
  padding: 0.15rem;
  a {
    padding: 0.6rem 0.1rem;
  }
`
const Nav = () => (
  <NavCont>
    <OutboundLink
      style={{
        color: `#ffffff`,
        display: `inline-block`,
        textDecoration: `none`,
        paddingLeft: `0.25rem`,
      }}
      href="http://www.ilsegnalibromagenta.it/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Il Segnalibro
    </OutboundLink>
    <div style={{ display: `flex`, flexDirection: `row` }}>
      <StyledLink to="/scopri">Scopri la Gattivista!</StyledLink>
      <OutboundLink
        href="https://www.facebook.com/La-Gattivista-2377680402461690/"
        title="Pagina Facebook della Gattivista"
        className={navStyles.social}
      ></OutboundLink>
    </div>
  </NavCont>
)

export default Nav
