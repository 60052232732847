import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "gattivista-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)
  return (
    <Img
      style={{ maxWidth: `500px`, margin: `0 auto` }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

const HeaderStyled = styled.header`
  background-color: #ec626b;
  box-shadow: 0px 4px 9px rgba(50, 50, 93, 0.4);
  margin: 0 auto;
  padding: 0.25rem;
  width: 100%;
  z-index: 1;
`

const Header = ({ siteTitle }) => (
  <HeaderStyled>
    <Link to="/" alt={siteTitle} aria-label="Home page Gattivista">
      <Image />
    </Link>
  </HeaderStyled>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
